import { useContractRead } from "wagmi"
import ShitCoin from '../abis/ShitCoin.json'

export const useShitCoinGetBalance = (props: {account?: string}) => {
        const {data, isError, isLoading} = useContractRead({
            address: '0x88b112a5394e197Ee37FBB3184C774aFcaf8EF3a',
            abi: ShitCoin.abi,
            functionName: 'balanceOf',
            args: [props.account]
        })

        return {data, isError, isLoading}
}
import React from 'react';
import logo from './assets/ShitCoinLogo.svg';
import './App.css';
import { Typography } from '@mui/material';
import { Swap } from './components/swap';

const Section1 = () => {

  const SubSection1 = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {/* <Typography fontSize={50} fontFamily={'gotham-rounded-bold'}> 
          Wellcome to this SHIT
        </Typography> */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px'
        }}>
        <img width={240} height={240} src={logo} className="App-logo" alt="logo" />
        <Typography fontSize={22} color={'white'} fontFamily={'gotham-rounded-bold'}> 
        99% of crypto projects are SHITS, ours is too, but we don't hide it from you, SHIT makes money, do you doubt it? Do a swap at side and check it out        </Typography>
        </div>
        <Typography fontSize={18} color={'white'} fontFamily={'gotham-rounded-bold'}> 
          Were you expecting a cool website? Man, this is a shitty project
        </Typography>
      </div>
    )
  }

  const SubSection2 = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Swap />
      </div>
    )
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#D97706',
      width: '100%',
      padding: 10
    }}>
      <SubSection1 />
      <SubSection2 />
    </div>
  )
}

const Section2 = () => {

  const SubSection1 = () => {
    return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '900px',
      gap: '20px',
      height: '100%'
    }}>
<iframe src="https://giphy.com/embed/JJFy5fjvOmnN5pOTJC" width="480" height="480" frameBorder="0" allowFullScreen></iframe><Typography fontSize={25} color={'white'} fontFamily={'gotham-rounded-bold'}> 
          SHIT has a fixed supply of 10M, after all, we don't want that much shit in the world
        </Typography>
      </div>
    )
  }

  const SubSection2 = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        width: '100%',
        padding: 10
      }}>
        <Typography fontSize={50} color={'orange'} fontFamily={'gotham-rounded-bold'}> 
          Why NOT?
        </Typography>
        <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px'
    }}>
<Typography fontSize={30} color={'orange'} fontFamily={'gotham-rounded-bold'}> 
        You're full of SHIT Coins in your portfolio, but these coins lie to YOU that they have a purpose, the only reason they're worth buying is because a lot of people think they're worth it and that makes the price go up and makes you money, it's the same with this here        </Typography>
        <iframe src="https://giphy.com/embed/ya6HU6ThrvplmnLEhA" width="480" height="270" frameBorder="0" allowFullScreen></iframe>
      
    </div>
        </div>
    )
  }

  const SubSection3 = () => {
    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '900px',
      gap: '20px'
    }}>
      <iframe src="https://giphy.com/embed/35UzSpVpCbq7EHszCp" width="300" height="300" frameBorder="0" allowFullScreen></iframe>    
      <Typography fontSize={30} color={'orange'} fontFamily={'gotham-rounded-bold'}> 
        The more you talk about this shit, the more money you make!
      </Typography>
      <div style={{
    display: 'flex',
    flexDirection: 'row',
    
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
  }}>
  </div>
      </div>
  }

  return (
    <>
    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
      <Typography fontSize={35} color={'white'} fontFamily={'gotham-rounded-bold'}> 
          Why buy SHIT?
        </Typography>
      </div>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      padding: 10
    }}>
      <SubSection3 />
      <SubSection2 />
      <SubSection1 />
    </div>
    </>

  )
}


function App() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1A282E',
      minHeight: '100vh'
    }}>
      <Section1 />
      <Section2 />
    </div>
  );
}

export default App;

import {
  Input,
  Typography,
  FormControl,
  Link,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";
import MaticLogo from "../assets/MaticLogo.svg";
import ShitLogo from "../assets/ShitCoinLogo.svg";
import SwipeIcon from "../assets/SwipeIcon.svg";
import { useState } from "react";
import {
  WagmiConfig,
  createConfig,
  configureChains,
  useConnect,
  useAccount,
  useBalance,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import ShitSale from '../abis/ShitSale.json'
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { polygon } from "viem/chains";
import { useShitCoinGetBalance } from "../services/ShitCoin";

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient } = configureChains(
  [polygon],
  [publicProvider()]
);

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  publicClient
});

const SwapTemplate = () => {
  const [matic, setMatic] = useState("0");
  const [shit, setShit] = useState("0");
  const RATE = 10;
  const { address, isConnected, connector } = useAccount();
  const { data } = useBalance({
    address,
  })
  const {data: shitCoinBalance} = useShitCoinGetBalance({account: address})
  const [error, setError] = useState<string>()
  const {config} = usePrepareContractWrite({
    address: '0x084E43d615bF50c523511570fc08C179fC82f1bE',
    abi: ShitSale.abi,
    functionName: 'buyTokens',
    value: BigInt(Number(matic) * Math.pow(10, 18))
  })
  const {data: transactionData, write, isLoading: transactionLoading} = useContractWrite(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: transactionData?.hash,
  })

  const handleSwap = async () => {
    const maticAmount = Number(matic);
    if(!matic || Number.isNaN(maticAmount) || maticAmount < 0.00001) {
        setError('Choose a valid matic value for buy')
        return
    }
    const userMaticBalance = Number(data?.formatted ?? 0)
    if(maticAmount > userMaticBalance) {
        setError('Insufficient balance')
        return
    }
    const chainId = await connector?.getChainId()
    console.log('CHAIN ID', chainId)
    if(!chainId || chainId != 137) {
        setError('Please change your network to Polygon at your provider')
        return
    }
    setError(undefined)
    if(write) write();
  }

  const ActionButton =  () => {
    if (!isConnected) {
      return <WalletConnectionOptions />;
    }



    if(transactionLoading || isLoading) {
        return       <Button
        style={{
          backgroundColor: "#F97316",
          color: "white",
          fontFamily: "gotham-rounded-bold",
          fontSize: 21,
        }}
      >
        <CircularProgress />
      </Button>
    }

    return (
      <Button
        onClick={handleSwap}
        style={{
          backgroundColor: "#F97316",
          color: "white",
          fontFamily: "gotham-rounded-bold",
          fontSize: 21,
        }}
      >
        SWAP
      </Button>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        backgroundColor: "#1A282E",
        padding: 5,
        gap: "0.5vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <Typography
          fontSize={21}
          color={"white"}
          fontFamily={"gotham-rounded-bold"}
        >
          Buy SHIT
        </Typography>

      </div>
      {address && 
              <Typography
              fontSize={14}
              color={"white"}
              fontFamily={"gotham-rounded-bold"}
            >
                {`Connected as: ${address.slice(0,4)}...${address.slice(address.length - 4)}`}
            </Typography>
      }
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(246, 246, 247, .3)",
          borderRadius: 10,
          padding: 5,
        }}
      >
        <Typography
          fontSize={18}
          color={"white"}
          fontFamily={"gotham-rounded-bold"}
        >
          You pay
        </Typography>
        <FormControl fullWidth variant="outlined">
          <Input
            type="number"
            id="MATIC_INPUT"
            size="medium"
            value={matic.toString()}
            onChange={(e) => {
                const value = e.target.value;

                // Verifica se o valor está vazio ou é um número positivo
                if (
                  value === "" ||
                  (/^\d+([.,]\d*)?$/.test(value) &&
                    parseFloat(value.replace(",", ".")) >= 0)
                ) {
                  setMatic(value);
                  setShit(
                    value !== ""
                      ? String(parseFloat(value.replace(",", ".")) * RATE)
                      : ""
                  );
                }
            }}
            style={{
              fontFamily: "gotham-rounded-bold",
              color: "white",
              fontSize: 21,
            }}
            endAdornment={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={18}
                  color={"white"}
                  fontFamily={"gotham-rounded-bold"}
                >
                  MATIC
                </Typography>
                <img src={MaticLogo} />
              </div>
            }
          />
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginTop: 5,
          }}
        >
          <Typography
            fontSize={18}
            color={"white"}
            fontFamily={"gotham-rounded-bold"}
          >
            Balance ${data?.formatted ?? 0}
          </Typography>
          <Link
            style={{
              color: "#F97316",
              fontSize: 18,
              fontFamily: "gotham-rounded-bold",
            }}
          >
            Max
          </Link>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img src={SwipeIcon} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(246, 246, 247, .3)",
          borderRadius: 10,
          padding: 5,
        }}
      >
        <Typography
          fontSize={18}
          color={"white"}
          fontFamily={"gotham-rounded-bold"}
        >
          You EARN
        </Typography>
        <FormControl fullWidth variant="outlined">
          <Input
            id="SHIT_INPUT"
            size="medium"
            value={shit.toString()}
            onChange={(e) => {
              const value = e.target.value;

              // Verifica se o valor está vazio ou é um número positivo
              if (
                value === "" ||
                (/^\d+([.,]\d*)?$/.test(value) &&
                  parseFloat(value.replace(",", ".")) >= 0)
              ) {
                setShit(value);
                setMatic(
                  value !== ""
                    ? String(parseFloat(value.replace(",", ".")) / RATE)
                    : ""
                );
              }
            }}
            style={{
              fontFamily: "gotham-rounded-bold",
              color: "white",
              fontSize: 21,
            }}
            endAdornment={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={18}
                  color={"white"}
                  fontFamily={"gotham-rounded-bold"}
                >
                  SHIT
                </Typography>
                <img src={ShitLogo} width={28} height={28} />
              </div>
            }
          />
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginTop: 5,
          }}
        >
          <Typography
            fontSize={18}
            color={"white"}
            fontFamily={"gotham-rounded-bold"}
          >
            Balance {shitCoinBalance ? '$'+(Number(shitCoinBalance) / Math.pow(10, 18)).toString() : '$0'}
          </Typography>
        </div>
      </div>
      <ActionButton />
      {error && 
              <Typography
              fontSize={14}
              color={"red"}
              fontFamily={"gotham-rounded-bold"}
            >
                {error}
            </Typography>
      }
    {transactionData?.hash && 
              <Typography
              fontSize={14}
              color={"white"}
              fontFamily={"gotham-rounded-bold"}
            >
                {`Your txhash: ${transactionData.hash}`}
            </Typography>
      }
    </div>
  );
};

const WalletConnectionOptions = () => {
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        style={{
          backgroundColor: "#F97316",
          color: "white",
          fontFamily: "gotham-rounded-bold",
          fontSize: 21,
        }}
      >
        Connect Wallet
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px", backgroundColor: '#1A282E', maxWidth: '50%', position: 'absolute', top: '30%', left: '40%', padding: 30, borderRadius: 10 }}>
        <Typography
          fontSize={18}
          color={"white"}
          fontFamily={"gotham-rounded-bold"}
        >
          Choose your favorite provider
        </Typography>
          {connectors.map((connector) => (
                  <Button
                  disabled={!connector.ready}
                  key={connector.id}
                  onClick={() => connect({ connector })}
                  style={{
                    backgroundColor: "#F97316",
                    color: "white",
                    fontFamily: "gotham-rounded-bold",
                    fontSize: 21,
                  }}
                >
             {connector.name}
              {!connector.ready && " (unsupported)"}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                " (connecting)"}                
                </Button>
          ))}

          {error && <div>{error.message}</div>}
        </div>
      </Modal>
    </>
  );
};

export const Swap = () => {
  return (
    <WagmiConfig config={config}>
      <SwapTemplate />
    </WagmiConfig>
  );
};
